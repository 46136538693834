import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
const resources = {
    es: {
        translation: {
            login: {
                identifying_user: 'Identificando usuario',
                logging_in: 'Iniciando sesión',
            },
            menu: {
                survey: 'Encuesta',
                code: 'Código',
            },
            event_attendee_not_on_event_day_error: 'No te encuentras en el día del evento',
            event_attendee_too_late_for_event_error:
                'No puedes contestar la encuesta después de que ha pasado 1 hora del inicio del evento',
            home: {
                answer_survey_for: 'Contestar encuesta para',
                survey_not_answered_yet: 'Aún no contestas la encuesta el día de hoy',
                last_survey_answered_at: 'Última encuesta contestada el {{date, datetime}}',
                survey_already_answered_today: 'Ya has contestado la encuesta el día de hoy',
            },
            survey: {
                confirmation_prompt: {
                    title: '¿Seguro que desea enviar su encuesta?',
                    message: 'Acaba de seleccionar que tiene un síntoma',
                },
                first_question: '¿Has tenido tos o dolor de garganta?',
                second_question: '¿Tiene dolor de cabeza?',
                third_question: '¿Tiene dolor de cuerpo o malestar general?',
                fourth_question: '¿Ha tenido fiebre?',
                fifth_question: '¿Tiene alguna dificultad para respirar?',
                sixth_question: '¿Ha tenido contacto con alguna persona con esos síntomas en los últimos  5 días?',
                send: 'Enviar',
                sent: 'Encuesta enviada',
                answer_all_questions: 'Por favor contesta todas las preguntas',
            },
            qrcode: {
                welcome: 'Bienvenido a la UANL',
                survey_scanned:
                    'Has contestado la encuesta e ingresado a tu dependencia, con el código en amarillo podrás reingresar',
                symptoms_detected:
                    'Se ha detectado que presentas síntomas por lo cual no puedes entrar a las instalaciones de la UANL',
                survey_not_answered:
                    'No has contestado la encuesta por lo cual no puedes entrar a las instalaciones de la UANL',
            },
            cancel: 'Cancelar',
            confirm: 'Confirmar',
            loading: 'Cargando',
            yes: 'Sí',
            no: 'No',
        },
    },
    en: {
        translation: {
            menu: {
                survey: 'Survey',
                code: 'QR Code',
            },
            login: {
                identifying_user: 'Identifying user',
                logging_in: 'Logging in',
            },
            event_attendee_not_on_event_day_error: 'You are not in the event date',
            event_attendee_too_late_for_event_error: 'Unable to answer the survey 1 hour after the event starts',
            home: {
                answer_survey_for: 'Answer survey for',
                survey_not_answered_yet: 'You haven’t answered today´s survey yet.',
                last_survey_answered_at: 'Last survey answered at {{date, datetime}}',
                survey_already_answered_today: 'You have answered today´s survey',
            },
            survey: {
                confirmation_prompt: {
                    title: 'Proceed to confirm your survey? ',
                    message: 'You just selected that you present a symptom',
                },
                first_question: 'Have you had cough or sore throat?',
                second_question: 'Do you have a headache?',
                third_question: 'Do you have body pain or general physical discomfort?',
                fourth_question: 'Have you had fever?',
                fifth_question: 'Do you have breathing difficulties?',
                sixth_question:
                    'Have you been in contact with anyone who has presented these symptoms in the last 5 days?',
                send: 'Send',
                sent: 'Survey sent',
                answer_all_questions: 'Please answer all the questions',
            },
            qrcode: {
                welcome: 'Welcome to the UANL',
                survey_scanned:
                    'You have answered the survey and signed into your unit / school. You may re-enter with code in yellow',
                symptoms_detected:
                    'It´s been detected that you present symptoms that prevent you from accessing the UANL facilities',
                survey_not_answered:
                    'You haven´t answered the survey yet, therefore you may not enter the UANL facilities',
            },
            cancel: 'Cancel',
            confirm: 'Confirm',
            loading: 'Loading',
            yes: 'Yes',
            no: 'No',
        },
    },
};
export default i18n.use(initReactI18next).init({
    resources,
    lng: 'es',
    interpolation: { 
        escapeValue: false,
    },
});
