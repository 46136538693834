import { Typography } from '@material-ui/core';
import { ExternalStudentsLoginForm } from './ExternalStudentsLoginForm';
import React from 'react';

export const ExternalStudentsLogin: React.FC = ({ children }) => {
    return (
        <div className="flex flex-col justify-center w-full h-full bg-white Login items-center">
            <img src="/images/LogoGrande.png" alt="Logo" className="mb-2 md:mb-4 h-20 md:h-28" />
            <div className="w-11/12 md:w-1/2 lg:w-4/12 p-4 shadow-lg py-12 flex flex-col space-y-2">
                <Typography align="center" variant="h5">
                    Bienvenido de vuelta
                </Typography>
                <ExternalStudentsLoginForm />
            </div>
            {/* <Typography
				className="text-gray-600"
				sx={{ mt: "1em" }}
				align="center"
				variant="subtitle2"
			>
				Aplicación de uso exclusivo para estudiantes inscritos en modalidad
				híbrida
			</Typography> */}
            <img src="/images/LogoUANLGrande.png" alt="Logo" className="mt-4 h-16 bottom-12" />
        </div>
    );
};
