import { TeacherDependency } from '@app/domain/Teacher/TeacherDependency';
import { Careers } from '@app/domain/Career/Careers';
import { Career } from '@app/domain/Career/Career';
export type TeacherDependenciesProps = {
    value: TeacherDependency[];
};
export class TeacherDependencies extends Careers {
    constructor(public props: TeacherDependenciesProps) {
        super();
    }
    get value(): Career[] {
        return this.props.value;
    }
}
