import firebase from "firebase/app";
import { useMutation } from "react-query";
import React, { useState } from "react";
import { PhoneLoginSecondStageFormInputs } from "./SecondStageForm";

export function useSecondStage(codeVerifier: firebase.auth.ConfirmationResult) {
  const [validationErrors, setValidationErrors] = useState<
    Record<keyof PhoneLoginSecondStageFormInputs, string | null>
  >({
    code: null,
  });
  const { mutate, error, isLoading, isSuccess } = useMutation<
    void,
    Error,
    PhoneLoginSecondStageFormInputs
  >("verifyCode", async ({ code }) => {
    setValidationErrors({
      code: null,
    });
    try {
      await codeVerifier.confirm(code);
    } catch (error: any) {
      if (error?.code == "auth/invalid-verification-code")
        throw new Error("Código inválido");
      if (error?.code == "auth/missing-verification-code")
        throw new Error("Por favor introduce un código");
      if (error?.code == "auth/invalid-verification-id")
        throw new Error(
          "Tu número de celular no está asciado a ninguna cuenta"
        );
      throw new Error("Error desconocido");
    }
  });
  return {
    verifyCode: mutate,
    isVerifyingCode: isLoading,
    codeVerified: isSuccess,
    verifyCodeError: error,
    isFormInvalid: Object.entries(validationErrors).some(
      ([_, validationError]) => validationError !== null
    ),
    validationErrors,
  };
}
