import { API_BASE_URL } from '@app/services/constants';
import { apiHttpClient } from '@app/clients/http/apiHttpClient';
export class EventAttendeesService {
    async login(email: string, invoice: string, dependencyId: string): Promise<{ jwt: string }> {
        const { data } = await apiHttpClient.post(`${API_BASE_URL}/event-attendees/login`, {
            email,
            invoice,
            dependencyId,
        });
        return data;
    }
}
export const eventAttendeesService = new EventAttendeesService();
