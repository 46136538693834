import React, { useEffect } from 'react'
import { useAuthStore } from '@app/components/FirebaseAuth/FirebaseAuthProvider'
import { EventAttendeeLoginLoadingScreen } from '@app/pages/event-attendee-login/EventAttendeeLoginLoadingScreen'
import { QuestionMarkCircleIcon } from '@heroicons/react/outline'
import { LinearProgress } from '@material-ui/core'
import { Redirect, useLocation } from 'react-router-dom'
import { useUserPreferencesStore } from '@app/stores/UserPreferencesStore'
import { useUserStore } from '@app/stores/UserStore'
import { useTranslation } from 'react-i18next'
import queryClient from '@app/clients/queryClient'
import { EventAttendeeLoginTryLogin } from '@app/pages/event-attendee-login/EventAttendeeLoginTryLogin'
import { z } from 'zod'

export const EventAttendeeLogin = () => {
    const { t, i18n } = useTranslation();
    const authStore = useAuthStore();
    const userPreferences = useUserPreferencesStore();
    const userStore = useUserStore();
    const location = useLocation();
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const lang = params.get('lang');
        if (['en', 'es'].includes(lang!)) {
            i18n.changeLanguage(lang!).catch();
        }
    }, []);
    if (authStore.isAuthenticatingUser)
        return (
            <EventAttendeeLoginLoadingScreen icon={<QuestionMarkCircleIcon className="w-20 h-20 text-gray-200" />}>
                <p className="text-white text-xl text-center">{t('login.identifying_user')}...</p>
                <LinearProgress />
            </EventAttendeeLoginLoadingScreen>
        );
    const selectPreferredCareerIfPossible = () => {
        const params = new URLSearchParams(location.search);
        const validationResult = z.string().safeParse(params.get('eventId'));
        if (validationResult.success && userStore.selectedCareer === null) {
            userStore.selectCareer({
                key: params.get('eventId')!,
                name: params.get('eventName')!,
            });
            userPreferences.setPreferredCareer(validationResult.data);
            queryClient.invalidateQueries(['profile', null]);
            // userStore.selectCareer(null);
        }
        return true;
    };
    if (authStore.user) {
        selectPreferredCareerIfPossible();
        return <Redirect to="/app" />;
    }
    return <EventAttendeeLoginTryLogin />;
};
