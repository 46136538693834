import React from 'react';
import InternalStudentsTeachersLoginLayout from './InternalStudentsTeachersLoginLayout';
import InternalStudentsTeachersLoginForm from './InternalStudentsTeachersLoginForm';

const InternalStudentsTeachersLogin = () => {
    return (
        <InternalStudentsTeachersLoginLayout>
            <InternalStudentsTeachersLoginForm />
        </InternalStudentsTeachersLoginLayout>
    );
};

export default InternalStudentsTeachersLogin;
