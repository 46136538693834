import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import muiTheme from './theme/muiTheme';
import queryClient from './clients/queryClient';
import { QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@material-ui/core';
import { AuthProvider } from './components/FirebaseAuth/FirebaseAuthProvider';
import { auth } from './services/firebase';
import { HashRouter } from 'react-router-dom';
import './index.css';
import { ToastProvider } from './components/Toast/ToastContext';
import { ConfirmationModalProvider } from './components/ConfirmationModal/ConfirmationModal';
import { ReactQueryDevtools } from 'react-query/devtools';
import './clients/http/interceptors/errorsInterceptor';
import './i18n/i18n';
const onUser = async () => {};
ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ThemeProvider theme={muiTheme}>
            <ToastProvider>
                <ConfirmationModalProvider>
                    <AuthProvider onUser={onUser} auth={auth}>
                        <HashRouter>
                            <App />
                        </HashRouter>
                    </AuthProvider>
                </ConfirmationModalProvider>
            </ToastProvider>
        </ThemeProvider>
    </QueryClientProvider>,
    document.getElementById('root')
);
