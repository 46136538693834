import { Backdrop, Button, Dialog, styled } from '@material-ui/core';
import React, { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CustomBackdrop = styled(Backdrop, {
    name: 'MuiModal',
    slot: 'Backdrop',
})({
    backgroundColor: 'transparent',
});

type ToastOptions = {
    title: string;
    message: string;
};

export const ConfirmationModalContext = createContext({
    closeModal: () => {},
    isOpen: false,
    open: (options: ToastOptions) => new Promise<boolean>((res) => res(true)),
});

export const ConfirmationModalProvider: React.FC = ({ children }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState({
        title: 'Title',
        message: 'Message',
    });
    function handleClose() {
        setIsOpen(false);
    }
    const [handleCancel, setHandleCancel] = useState<() => void>(() => {});
    const [handleConfirm, setHandleConfirm] = useState<() => void>(() => {});
    async function open(options: ToastOptions): Promise<boolean> {
        setOptions(options);
        setIsOpen(true);
        return new Promise((res) => {
            setHandleCancel(() => () => {
                res(false);
                handleClose();
            });
            setHandleConfirm(() => () => {
                res(true);
                handleClose();
            });
        });
    }
    return (
        <ConfirmationModalContext.Provider value={{ isOpen, open, closeModal: handleClose }}>
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={isOpen}
                onBackdropClick={handleCancel}
                BackdropComponent={CustomBackdrop}
                style={{
                    wordBreak: 'break-all',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                }}
            >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 className="text-lg leading-6 font-medium text-gray-900 break-normal" id="modal-title">
                                {options.title}
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500 break-normal">{options.message}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 px-4 items-center py-3 flex flex-row justify-center sm:justify-end">
                    <Button onClick={handleCancel} style={{ textTransform: 'none' }}>
                        {t('cancel')}
                    </Button>
                    <div className="px-2"></div>
                    <Button onClick={handleConfirm} variant="contained" style={{ textTransform: 'none' }}>
                        {t('confirm')}
                    </Button>
                </div>
            </Dialog>
            {children}
        </ConfirmationModalContext.Provider>
    );
};
