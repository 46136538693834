import axios from "axios";
import { API_BASE_URL } from "../../../../services/constants";
import firebase from "firebase/app";
import { auth } from "../../../../services/firebase";
import { useMutation } from "react-query";
import { isInvalidInputError } from "@common/helpers/errorsHandler";
import React, { useState } from "react";
import { PhoneLoginFirstStageFormInputs } from "./FirstStageForm";

type Response =
  | {
      canSignInWithPhoneNumber: false;
      reason: string;
    }
  | {
      canSignInWithPhoneNumber: true;
    };

export function useFirstStage(
  onMessageSent: React.Dispatch<
    React.SetStateAction<firebase.auth.ConfirmationResult | null>
  >
) {
  const [validationErrors, setValidationErrors] = useState<
    Record<keyof PhoneLoginFirstStageFormInputs, string | null>
  >({
    matricula: null,
    phoneNumber: null,
  });
  const { mutate, error, isLoading, isSuccess } = useMutation<
    void,
    Error,
    PhoneLoginFirstStageFormInputs
  >("sendMessage", async ({ matricula, phoneNumber }) => {
    setValidationErrors({
      matricula: null,
      phoneNumber: null,
    });
    try {
      const { data } = await axios.get<Response>(
        `${API_BASE_URL}/user/canSignInWithPhoneNumber/${phoneNumber || "0"}`
      );
      if (!data.canSignInWithPhoneNumber) {
        setValidationErrors({
          matricula: null,
          phoneNumber:
            "No existe ningún usuario asociado a ese número de celular",
        });
        return;
      }
      const appVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha_verifier",
        { size: "invisible" }
      );
      const verifier = await auth.signInWithPhoneNumber(
        `+52${phoneNumber}`,
        appVerifier
      );
      onMessageSent(verifier);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (isInvalidInputError(error.response?.data)) {
          setValidationErrors(error.response?.data.errors);
        }
        switch (error.response?.status) {
          case 500:
            throw new Error("Algo salió mal, por favor intentalo de nuevo");
          default:
            throw new Error("Error desconocido");
        }
      }
      throw new Error("Error desconocido");
    }
  });
  return {
    sendMessage: mutate,
    isSendingMessage: isLoading,
    messageSent: isSuccess,
    sendMessageError: error,
    isFormInvalid: Object.entries(validationErrors).some(
      ([_, validationError]) => validationError !== null
    ),
    validationErrors,
  };
}
