import { useContext } from 'react';
import { ToastContext } from '@app/components/Toast/ToastContext';
import axios from 'axios';
import { useMutation } from 'react-query';
import queryClient from '../../clients/queryClient';
import { API_BASE_URL } from '../constants';
import { useProfile } from '@app/services/api/useProfile/index';
import { TeacherProfile } from '@app/domain/Profile/TeacherProfile';
import { useTranslation } from 'react-i18next';
import { EventAttendeeProfile } from '@app/domain/Profile/EventAttendeeProfile';
import { useUserStore } from '@app/stores/UserStore';

type sendSurveyArgs = {
    answers: boolean[];
    clave_carrera: string;
};

const sendSurveyErrorBag: Record<number, string> = {
    403: 'No puedes contestar la encuesta aún',
    412: 'Ya has contestado la encuesta el día de hoy, por favor espera a tu siguiente día de clases',
    500: 'Algo salió mal',
};

type voidFn = () => void;

type Events = {
    onSuccess?: voidFn;
};

export const useSendSurveyMutation = ({ onSuccess }: Events) => {
    const { profile } = useProfile();
    const { t } = useTranslation();
    const { selectedCareer } = useUserStore();
    const toast = useContext(ToastContext);
    const { mutateAsync, isLoading, isError, error, isSuccess } = useMutation<void, Error, sendSurveyArgs>(
        async ({ answers, clave_carrera }): Promise<void> => {
            const endpoint = answers.includes(true) ? '/survey/notifySimptoms' : '/survey/send';
            try {
                await axios.post(`${API_BASE_URL}${endpoint}`, {
                    respuestas: answers,
                    clave_carrera,
                });
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    const status = error.response!.status;
                    throw new Error(sendSurveyErrorBag[status] || 'Error desconocido');
                }
                throw new Error('Error desconocido');
            }
        },
        {
            onSuccess: () => {
                let message = 'Tener uno o más síntomas justifica la falta a clases presenciales.';
                if (profile instanceof TeacherProfile) {
                    message =
                        'Si tiene uno o más síntomas por favor comuníquese con el departamento de RH de su dependencia';
                }
                if (profile instanceof EventAttendeeProfile) message = '';
                queryClient.invalidateQueries('profile');
                queryClient.invalidateQueries(['profile', selectedCareer]);
                onSuccess && onSuccess();
                toast.open({
                    title: t('survey.sent'),
                    message: message,
                });
            },
        }
    );
    return {
        sendSurvey: mutateAsync,
        sendingSurvey: isLoading,
        surveySentFailed: isError,
        surveySentError: error,
        surveySent: isSuccess,
    };
};
