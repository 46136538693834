import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import axios from 'axios';
import { getMessagingToken } from '../../services/firebase';
import { useUserStore } from '@app/stores/UserStore';
type User = firebase.User;
type AuthStoreState = {
    user: User | null;
    isAuthenticatingUser: boolean;
};
export const AuthContext = createContext<AuthStoreState>({
    isAuthenticatingUser: true,
    user: null,
});
type AuthProviderProps = {
    children: ReactNode | ReactNode[];
    auth: firebase.auth.Auth;
    onUser: (user: firebase.User) => any;
};
export function AuthProvider({ children, auth }: AuthProviderProps) {
    const [authStoreState, setAuthStoreState] = useState<AuthStoreState>({
        isAuthenticatingUser: true,
        user: null,
    });
    const { setFcmToken } = useUserStore();
    useEffect(() => {
        const unsubscribeFromAuthStateChanges = auth.onAuthStateChanged(async (user) => {
            if (user) {
                axios.defaults.headers.authorization = `Bearer ${await user.getIdToken()}`;
                const getInfo = async () => {
                    setFcmToken(await getMessagingToken());
                    setAuthStoreState({
                        user,
                        isAuthenticatingUser: false,
                    });
                };
                getInfo();
            } else {
                setAuthStoreState({
                    user,
                    isAuthenticatingUser: false,
                });
            }
        });
        return () => {
            unsubscribeFromAuthStateChanges();
        };
    }, []);
    return <AuthContext.Provider value={authStoreState}>{children}</AuthContext.Provider>;
}
export const useAuthStore = () => useContext(AuthContext);
