import {
    isEventAttendeeProfileDTO,
    isInternalStudentProfileDTO,
    isTeacherProfileDTO,
    ProfileDTO,
} from '@common/dtos/ProfileDTO';
import { InternalStudentProfile } from '@app/domain/Profile/InternalStudentProfile';
import { InternalStudentCareers } from '@app/domain/InternalStudent/InternalStudentCareers';
import { internalStudentCareerFromDTO } from '@app/domain/InternalStudent/InternalStudentCareer';
import { TeacherProfile } from '@app/domain/Profile/TeacherProfile';
import { TeacherDependencies } from '@app/domain/Teacher/TeacherDependencies';
import { teacherDependencyFromDTO } from '@app/domain/Teacher/TeacherDependency';
import { EventAttendeeProfile } from '@app/domain/Profile/EventAttendeeProfile';
import { Events } from '@app/domain/EventAttendee/Events';
import { eventFromDTO } from '@app/domain/EventAttendee/Event';
import { ExternalStudentProfile } from '@app/domain/Profile/ExternalStudentProfile';
import { ExternalStudentCareers } from '@app/domain/ExternalStudent/ExternalStudentCareers';
import { externalStudentCareerFromDTO } from '@app/domain/ExternalStudent/ExternalStudentCareer';
import { Profile } from '@app/domain/Profile/Profile';
export function userProfileFromDTO(dto: ProfileDTO): Profile {
    if (isInternalStudentProfileDTO(dto)) {
        return new InternalStudentProfile({
            userId: dto.user_id,
            enrollment: dto.enrollment,
            selectedDependencyId: dto.selectedDependencyId,
            latestSurveyIsFromToday: dto.latestSurveyIsFromToday,
            name: dto.name,
            unseenNotifications: dto.unseen_notifications,
            canAnswerAgain: !dto.encuesta_contestada,
            lastSurveyWasAnsweredAt: new Date(dto.fecha_contestada),
            hasSimptoms: dto.tiene_sintomas,
            daysBlocked: dto.dias_bloqueado,
            careers: new InternalStudentCareers({ value: dto.careers.map(internalStudentCareerFromDTO) }),
            photo: dto.foto,
            vaccinationCertificate: {
                url: dto.vaccinationCertificateUrl,
                status: dto.vaccinationCertificateStatus,
            },
        });
    }
    if (isTeacherProfileDTO(dto)) {
        return new TeacherProfile({
            userId: dto.user_id,
            teacherKey: dto.teacherKey,
            name: dto.name,
            selectedDependencyId: '',
            latestSurveyIsFromToday: dto.latestSurveyIsFromToday,
            unseenNotifications: dto.unseen_notifications,
            canAnswerAgain: !dto.encuesta_contestada,
            lastSurveyWasAnsweredAt: new Date(dto.fecha_contestada),
            hasSimptoms: dto.tiene_sintomas,
            daysBlocked: dto.dias_bloqueado,
            dependencies: new TeacherDependencies({ value: dto.dependencies.map(teacherDependencyFromDTO) }),
            photo: dto.foto,
            vaccinationCertificate: {
                url: dto.vaccinationCertificateUrl,
                status: dto.vaccinationCertificateStatus,
            },
        });
    }
    if (isEventAttendeeProfileDTO(dto)) {
        return new EventAttendeeProfile({
            userId: dto.user_id,
            selectedDependencyId: '',
            latestSurveyIsFromToday: dto.latestSurveyIsFromToday,
            name: dto.name,
            canAnswerSurvey: dto.can_answer_survey.success
                ? { success: true }
                : { success: false, reason: dto.can_answer_survey.reason! },
            unseenNotifications: dto.unseen_notifications,
            canAnswerAgain: !dto.encuesta_contestada,
            lastSurveyWasAnsweredAt: new Date(dto.fecha_contestada),
            hasSimptoms: dto.tiene_sintomas,
            daysBlocked: dto.dias_bloqueado,
            photo: dto.foto,
            role: dto.role,
            events: new Events({ value: dto.events.map(eventFromDTO) }),
            vaccinationCertificate: {
                url: dto.vaccinationCertificateUrl,
                status: dto.vaccinationCertificateStatus,
            },
        });
    }
    return new ExternalStudentProfile({
        userId: dto.user_id,
        name: dto.name,
        selectedDependencyId: '',
        latestSurveyIsFromToday: dto.latestSurveyIsFromToday,
        unseenNotifications: dto.unseen_notifications,
        canAnswerAgain: !dto.encuesta_contestada,
        lastSurveyWasAnsweredAt: new Date(dto.fecha_contestada),
        hasSimptoms: dto.tiene_sintomas,
        daysBlocked: dto.dias_bloqueado,
        hasClassToday: dto.puedo_contestar_encuesta,
        allCareersExpired: dto.haveAllCareersExpired,
        careers: new ExternalStudentCareers({ value: dto.careers.map(externalStudentCareerFromDTO) }),
        photo: dto.foto,
        vaccinationCertificate: {
            url: dto.vaccinationCertificateUrl,
            status: dto.vaccinationCertificateStatus,
        },
    });
}
