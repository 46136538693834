import { Button, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
const Terms = () => {
    const h = useHistory();
    // useEnsureExternalStudentHasValidCareers()
    return (
        <div className="min-h-screen grid place-items-center">
            <Paper elevation={7} className=" w-11/12 md:w-1/2 p-8">
                <Typography sx={{ mb: '0.5em' }} variant="h5">
                    Aviso
                </Typography>
                <Typography align="justify" variant="body1">
                    El presente cuestionario tiene como objetivo ser un filtro previo a ingresar a las instalaciones de
                    la UANL, para así identificar a los asistentes que presenten síntomas y/o que hayan tenido contacto
                    con algún caso que ya esté bajo revisión por COVID-19. El cuestionario está destinado a ser
                    contestado por los asistentes antes de cada sesión presencial para garantizar su acceso seguro,
                    evitar el ingreso de potenciales infectados por COVID-19 y prevenir su transmisión a otras personas
                    de la UANL. Un asistente identificado por medio de este cuestionario debe comunicarse a su
                    dependencia
                </Typography>
                <Button
                    onClick={() => h.push('/app')}
                    variant="contained"
                    data-testid="accept-btn"
                    sx={{ mt: '0.5em' }}
                >
                    Acepto
                </Button>
            </Paper>
        </div>
    );
};
export default Terms;
