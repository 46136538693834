import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/storage';
import 'firebase/performance';
import type fb from 'firebase';
/** Configuración de producción de firebase */
const prodFirebaseConfig = {
    apiKey: 'AIzaSyBTg9Wz17PoXzIhRbeG-AD-NDcRFBNk820',
    authDomain: 'regresoaclasesuanl.firebaseapp.com',
    projectId: 'regresoaclasesuanl',
    storageBucket: 'regresoaclasesuanl.appspot.com',
    messagingSenderId: '862842457577',
    appId: '1:862842457577:web:61010744dca982d1bccf78',
    measurementId: 'G-3Q1GN7FVB3',
};
/** Configuración de qa de firebase */
const devFirebaseConfig = {
    apiKey: 'AIzaSyCRyfeXP96TT7lu7QXAhVbD47jd2rK-jf0',
    authDomain: 'asisteseguroqa.firebaseapp.com',
    projectId: 'asisteseguroqa',
    storageBucket: 'asisteseguroqa.appspot.com',
    messagingSenderId: '200289881054',
    appId: '1:200289881054:web:e7311f232af68a62c16b4f',
};
const firebaseConfig = import.meta.env.VITE_FIREBASE_PROJECT === 'prod' ? prodFirebaseConfig : devFirebaseConfig;
const app = firebase.initializeApp(firebaseConfig);
export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();
export const perf = app.performance();
export const analytics = app.analytics();
console.log(`
    Mode: ${import.meta.env.MODE}
    Firebase Project Id: ${firebaseConfig.projectId}

`);
const shouldUseEmulators = import.meta.env.MODE === 'dev_local';
if (shouldUseEmulators) {
    console.log(`
    Using auth and firestore emulators
    
`);
}
if (shouldUseEmulators) {
    // @ts-ignore
    db.useEmulator('localhost', 8080, {
        mockUserToken: { aud: '123', sub: '999990' },
    });
    storage.useEmulator('localhost', 9199);
    // @ts-ignore
    auth.useEmulator('http://localhost:9099', { disableWarnings: true });
}
export let messaging: fb.messaging.Messaging;
try {
    messaging = app.messaging();
    messaging.onBackgroundMessage((msg) => {
        console.log(msg);
    });
    messaging.onMessage((msg) => {
        console.log(msg);
    });
} catch (error) {}
export const getMessagingToken = async () => {
    if (!messaging) return null;
    try {
        const fcm = await messaging?.getToken({
            vapidKey:
                firebaseConfig.projectId === 'asisteseguroqa'
                    ? 'BK0CYSM2HWTxm1fCpQlpfJhhN414-Wg0PbmbnOIiWkepoPMJsPJOj3oJq8hWShBFGToNgOeU7SHwsBuF_G3sxw0'
                    : 'BMwNVU_RJTWM5y7NERkM-SBPt2gcjoNAUB5ACOo1yIRZRHf-gPGO8GsIWGYzQa3cM7WH8vrCnMXuHRFF09YCI8o',
        });
        return fcm;
    } catch (error) {
        console.log(error);
        return null;
    }
};
export const surveysCollection = (id: string) => db.collection('usuarios').doc(id).collection('encuestas');
