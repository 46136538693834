import { InternalStudentCareerDTO } from '@common/dtos/InternalStudentCareerDTO';
import { Career } from '@app/domain/Career/Career';
export type InternalStudentCareerProps = {
    key: string;
    name: string;
};
export class InternalStudentCareer implements Career {
    constructor(public props: InternalStudentCareerProps) {}
    /**
     * Career implementation
     */
    get key(): string {
        return this.props.key;
    }
    get name(): string {
        return this.props.name;
    }
}
export function internalStudentCareerFromDTO(dto: InternalStudentCareerDTO): InternalStudentCareer {
    return new InternalStudentCareer({
        name: dto.name,
        key: dto.key,
    });
}
