import { createTheme, responsiveFontSizes } from "@material-ui/core";

export default responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#004A8F",
      },
      secondary: {
        main: "#FBC43D",
      },
    },
  })
);
