import { Profile, ProfileProps } from '@app/domain/Profile/Profile';
import { ExternalStudentCareers } from '@app/domain/ExternalStudent/ExternalStudentCareers';
export type ExternalStudentProfileProps = ProfileProps & {
    careers: ExternalStudentCareers;
    hasClassToday: boolean;
    allCareersExpired: boolean;
};
export class ExternalStudentProfile extends Profile {
    constructor(public props: ExternalStudentProfileProps) {
        super(props);
    }
    public get careers(): ExternalStudentCareers {
        return this.props.careers;
    }
}
