import { Careers } from '@app/domain/Career/Careers';
import { InternalStudentCareer } from '@app/domain/InternalStudent/InternalStudentCareer';
import { Career } from '@app/domain/Career/Career';
export type InternalStudentCareersProps = {
    value: InternalStudentCareer[];
};
export class InternalStudentCareers extends Careers {
    constructor(public props: InternalStudentCareersProps) {
        super();
    }
    get value(): Career[] {
        return this.props.value;
    }
}
