import axios from 'axios';
import { auth } from '../../../firebase';
import { API_BASE_URL } from '../../../constants';
import { useMutation } from 'react-query';
import { useState } from 'react';
import queryClient from '@app/clients/queryClient';
import { AuthenticationError } from '@app/errors/AuthenticationError';
import { ValidationError } from '@app/errors/ValidationError';
import { InternalServerError } from '@app/errors/InternalServerError';
import { PermissionDeniedError } from '@app/errors/PermissionDeniedError';

type LoginOkResponse = {
    token: string;
};

type LoginValidationErrors = {
    teacherKey: null | string;
    matricula: null | string;
    password: null | string;
};

export const useLogin = () => {
    const defaultValidationErrors: LoginValidationErrors = {
        teacherKey: null,
        matricula: null,
        password: null,
    };
    const [isFormInvalid, setIsFormInvalid] = useState(false);
    const [validationErrors, setValidationErrors] = useState<LoginValidationErrors>(defaultValidationErrors);
    const { mutate, error, isLoading, isError, isSuccess } = useMutation<
        void,
        Error,
        { userIdentifier: string; password: string; isTeacher: boolean }
    >('login', async (credentials) => {
        setValidationErrors(defaultValidationErrors);
        setIsFormInvalid(false);
        try {
            const url = credentials.isTeacher ? `${API_BASE_URL}/teachers/login` : `${API_BASE_URL}/auth/login`;
            const body = credentials.isTeacher
                ? { teacherKey: credentials.userIdentifier, password: credentials.password }
                : { matricula: credentials.userIdentifier, password: credentials.password };
            const { data } = await axios.post<LoginOkResponse>(url, body);
            await queryClient.invalidateQueries();
            await auth.signInWithCustomToken(data.token);
        } catch (error) {
            if (error instanceof Error) {
                if (error.message === 'Network Error') {
                    throw new Error(
                        'No tienes internet o el servicio está caído, por favor intentalo de nuevo más tarde'
                    );
                }
            }
            if (error instanceof AuthenticationError) throw new Error('Contraseña inválida');
            if (error instanceof ValidationError) throw new Error('Campos incorrectos');
            if (error instanceof InternalServerError) throw new Error('Algo salió mal, por favor intentalo de nuevo');
            if (error instanceof PermissionDeniedError) throw new Error(error.message);
            throw new Error('Algo salió mal, por favor intentalo de nuevo');
        }
    });
    return {
        login: mutate,
        loggingIn: isLoading,
        loggedIn: isSuccess,
        errorLoggingIn: isError,
        loginError: error,
        isFormInvalid,
        validationErrors,
    };
};
