import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export const LoadingScreen = () => {
  return (
    <div className="h-screen w-screen bg-primary grid place-items-center">
      <Loader color="#2a86db44" height={80} width={80} type="Grid" />
    </div>
  );
};
