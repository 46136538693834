import React, { useState } from 'react';
import firebase from 'firebase/app';
import { PhoneLoginFirstStageForm } from './components/FirstStage/FirstStageForm';
import { PhoneLoginSecondStageForm } from './components/SecondStage/SecondStageForm';

export const ExternalStudentsLoginForm = () => {
    const [codeVerifier, setCodeVerifier] = useState<firebase.auth.ConfirmationResult | null>(null);
    return (
        <div>
            {codeVerifier === null ? (
                <PhoneLoginFirstStageForm onMessageSent={setCodeVerifier} />
            ) : (
                <PhoneLoginSecondStageForm codeVerifier={codeVerifier} />
            )}
        </div>
    );
};
