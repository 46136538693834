import { FieldPath } from "@google-cloud/firestore";
import { AxiosError } from "axios";
import type { ValidationError } from "class-validator";

export const isInvalidInputError = (obj: any) => "errors" in obj

export const parseValidationErrors = <Inputs extends Object>(
  error: AxiosError | null
): Record<keyof Inputs, string> | undefined => {
  if (!error) return undefined;
  const data = error.response?.data;
  const messages = data?.message as ValidationError[];
  if (!isInvalidInputError(data)) return undefined;
  const errorBag = {} as any;
  for (const field of messages) {
    errorBag[field.property] = "";
    for (const error in field.constraints) {
      errorBag[field.property] += `${field.constraints[error]}. `;
    }
  }
  return errorBag;
};

export const parseErrors = (validationErrors: ValidationError[]) => {
  const errorBag = {} as any;
  for (const field of validationErrors) {
    errorBag[field.property] = "";
    for (const error in field.constraints) {
      errorBag[field.property] += `${field.constraints[error]}. `;
    }
  }
  return errorBag;
};

const errorBag: Record<number, string> = {
  500: "Algo salió mal",
  403: "No tienes permiso para hacer eso",
  401: "No has iniciado sesión",
};

export const parseErrorMessage = (error: AxiosError | null) => {
  const res = error?.response;
  if (!res?.status) return undefined;
  if (res.status === 400) return "";
  const errorMsg = errorBag[res.status];
  return errorMsg || "Error desconocido";
};
