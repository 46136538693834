import { Profile, ProfileProps } from '@app/domain/Profile/Profile';
import { Careers } from '@app/domain/Career/Careers';
import { TeacherDependencies } from '@app/domain/Teacher/TeacherDependencies';
export type TeacherProfileProps = ProfileProps & {
    dependencies: TeacherDependencies;
    teacherKey: string;
};
export class TeacherProfile extends Profile {
    constructor(public props: TeacherProfileProps) {
        super(props);
    }
    public get careers(): Careers {
        return this.props.dependencies;
    }
}
