import { Backdrop, Button, Dialog, styled } from '@material-ui/core';
import React, { createContext, useState } from 'react';

const CustomBackdrop = styled(Backdrop, {
    name: 'MuiModal',
    slot: 'Backdrop',
})({
    backgroundColor: 'transparent',
});

type ToastOptions = {
    title: string;
    message: string;
    closable?: boolean;
};

export const ToastContext = createContext({
    closeModal: () => {},
    isOpen: false,
    open: (options: ToastOptions) => {},
});

export const ToastProvider: React.FC = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState<ToastOptions>({
        title: 'Este es el modal toast',
        message: 'Lorem ipsum dolor istet amet as dolor lorem ispum asete amzer',
        closable: true,
    });
    const handleClose = () => {
        if (!options.closable) return;
        setIsOpen(false);
    };
    const open = (options: ToastOptions) => {
        if (options.closable === undefined) options.closable = true;
        setOptions(options);
        setIsOpen(true);
    };
    return (
        <ToastContext.Provider value={{ isOpen, open, closeModal: handleClose }}>
            <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={isOpen}
                BackdropComponent={CustomBackdrop}
                style={{
                    wordBreak: 'break-all',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                }}
            >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 className="text-lg leading-6 font-medium text-gray-900 break-normal" id="modal-title">
                                {options.title}
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500 break-normal">{options.message}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 px-4 items-center py-3 flex flex-row justify-center sm:justify-end">
                    <Button variant="contained" onClick={handleClose} style={{ textTransform: 'none' }}>
                        Cerrar
                    </Button>
                </div>
            </Dialog>
            {children}
        </ToastContext.Provider>
    );
};
