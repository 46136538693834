import { ExternalStudentCareerDTO } from '@common/dtos/ExternalStudentCareerDTO';
import { Career } from '@app/domain/Career/Career';
export type ExternalStudentCareerProps = {
    enrollment: string;
    name: string;
};
export class ExternalStudentCareer implements Career {
    constructor(public props: ExternalStudentCareerProps) {}
    /**
     * Career implementation
     */
    get key(): string {
        return this.props.name;
    }
    get name(): string {
        return this.props.name;
    }
}
export function externalStudentCareerFromDTO(dto: ExternalStudentCareerDTO): ExternalStudentCareer {
    return new ExternalStudentCareer({
        name: dto.name,
        enrollment: dto.enrollment,
    });
}
