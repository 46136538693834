import React, { useState } from 'react';
import { AppBar, IconButton, Tab, Tabs, Typography, useMediaQuery } from '@material-ui/core';
import Home from './home/Home';
import QRCodePage from './qr-code/QRCode';
import Profile from './profile/Profile';
import { BellIcon, HomeIcon, QrcodeIcon, UserIcon } from '@heroicons/react/solid';
import Notifications from './notifications/Notifications';
import { Help } from '@material-ui/icons';
import { navigateToFaq } from '@app/components/helpers/navigateToFaq';
import { useProfile } from '@app/services/api/useProfile';
import { LoadingScreen } from '@app/components/LoadingScreen/LoadingScreen';
import { EventAttendeeProfile } from '@app/domain/Profile/EventAttendeeProfile';
import { useTranslation } from 'react-i18next';
import { NotFoundErrorPage } from '@app/pages/error-pages/NotFoundErrorPage';
import { NotFoundError } from '@app/errors/NotFoundError';

const TabName: Record<number, string> = {
    0: 'Código',
    1: 'Notificaciones',
    2: 'Perfil',
};
export const Main = () => {
    const isSmallMobile = useMediaQuery('(max-width: 600px)');
    const { t } = useTranslation();
    const { profile, error, errorLoadingProfile, loadingProfile } = useProfile();
    const [tab, setTab] = useState(0);
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };
    if (loadingProfile) return <LoadingScreen />;
    const shouldShowNotifications = !(profile instanceof EventAttendeeProfile);
    const shouldShowProfile = !(profile instanceof EventAttendeeProfile);
    const shownTabsSpec = [true, true, shouldShowNotifications, shouldShowProfile];
    const hiddenInLeft = (currentTabIndex: number) =>
        shownTabsSpec.filter((shouldShow, tabIndex) => !shouldShow && tabIndex < currentTabIndex).length;
    return (
        <div
            style={{
                paddingTop: profile instanceof EventAttendeeProfile ? '10px' : '70px',
            }}
            className="pb-[100px] min-h-screen relative bg-[#eeeeee]"
        >
            <AppBar
                sx={{
                    height: profile instanceof EventAttendeeProfile ? '0px' : '50px',
                    display: profile instanceof EventAttendeeProfile ? 'none' : 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#634700',
                }}
                position="fixed"
                color="secondary"
            >
                <Typography variant="h5">{TabName[tab]}</Typography>
                <IconButton onClick={navigateToFaq} sx={{ position: 'absolute' }} className="absolute right-2">
                    <Help />
                </IconButton>
            </AppBar>
            <AppBar
                sx={{
                    top: 'auto',
                    bottom: 0,
                    height: isSmallMobile ? 'auto' : '65px',
                }}
                position="fixed"
                color="primary"
            >
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    indicatorColor="secondary"
                    aria-label="tabs"
                >
                    <Tab
                        style={{ color: '#eeeeee' }}
                        sx={{ minWidth: '0px' }}
                        icon={<QrcodeIcon className="w-4 md:w-6" />}
                        label={isSmallMobile ? '' : t('menu.code')}
                    />
                    {shouldShowNotifications && (
                        <Tab
                            style={{ color: '#eeeeee' }}
                            sx={{ minWidth: '0px' }}
                            icon={
                                <div className="relative">
                                    {' '}
                                    <BellIcon className="w-4 md:w-6" /> {/* @ts-ignore */}
                                    {profile?.props?.unseenNotifications > 0 && (
                                        <span className="absolute  -top-1  -right-5 rounded-md bg-yellow-500 p-1">
                                            {profile?.props.unseenNotifications}
                                        </span>
                                    )}
                                </div>
                            }
                            label={isSmallMobile ? '' : 'Notificaciones'}
                        />
                    )}
                    {shouldShowProfile && (
                        <Tab
                            style={{ color: '#eeeeee' }}
                            sx={{ minWidth: '0px' }}
                            icon={<UserIcon className="w-4 md:w-6" />}
                            label={isSmallMobile ? '' : 'Perfil'}
                            data-testid="profile-btn"
                        />
                    )}
                </Tabs>
            </AppBar>
            <div>
                <TabPanel value={tab} index={0}>
                    <QRCodePage />
                </TabPanel>
                {shouldShowNotifications && (
                    <TabPanel value={tab} index={1 - hiddenInLeft(1)}>
                        <Notifications />
                    </TabPanel>
                )}
                {shouldShowProfile && (
                    <TabPanel value={tab} index={2 - hiddenInLeft(2)}>
                        <Profile />
                    </TabPanel>
                )}
            </div>
        </div>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = ({ index, value, children }: TabPanelProps) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
        >
            {value === index && children}
        </div>
    );
};
