import React from 'react';
import { useForm } from 'react-hook-form';
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core';
import { useLogin } from '../../services/api/auth/login/useLogin';
import { useToggle } from '../../hooks/useToggle';
import { Visibility, VisibilityOff } from '@material-ui/icons';
type LoginFormInputs = {
    userIdentifier: string;
    password: string;
    isTeacher: boolean;
};
const InternalStudentsTeachersLoginForm = () => {
    const { register, handleSubmit, getValues, watch } = useForm<LoginFormInputs>({
        reValidateMode: 'onSubmit',
        defaultValues: {
            userIdentifier: '',
            password: '',
            isTeacher: false,
        },
    });
    const isTeacher = watch('isTeacher');
    const [showPassword, toggleShowPassword] = useToggle(false);
    const { login, validationErrors, isFormInvalid, loggingIn, loginError } = useLogin();
    const onSubmit = async (credentials: LoginFormInputs) => {
        login(credentials);
    };
    return (
        <form className="flex flex-col space-y-4" onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="body2" className="text-red-500">
                {loginError?.message} &nbsp;
            </Typography>
            <TextField
                name="Identifier"
                label={isTeacher ? 'Clave de empleado' : 'Matrícula'}
                error={isFormInvalid}
                helperText={
                    isTeacher
                        ? validationErrors?.teacherKey ?? 'Introduce tu clave de empleado'
                        : validationErrors?.matricula ?? 'Introduce tu matrícula'
                }
                variant="outlined"
                size="small"
                type="text"
                inputProps={{
                    ...register('userIdentifier', {}),
                    'data-testid': 'enrollment-input',
                    'aria-label': 'matricula',
                    min: '0',
                    id: 'Matrícula',
                }}
            />
            <TextField
                name="Contraseña"
                label="Contraseña"
                error={isFormInvalid}
                helperText={validationErrors?.password || 'Introduce tu contraseña'}
                variant="outlined"
                size="small"
                inputProps={{
                    ...register('password'),
                    type: showPassword ? 'text' : 'password',
                    'data-testid': 'password-input',
                    'aria-label': 'password',
                    id: 'Contraseña',
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={toggleShowPassword}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <FormGroup className="ml-4">
                <FormControlLabel
                    className="text-gray-500"
                    control={<Checkbox {...register('isTeacher')} />}
                    label="Soy maestro"
                />
            </FormGroup>
            <Button
                type="submit"
                disabled={loggingIn}
                aria-label="submit"
                color="primary"
                variant="contained"
                fullWidth
                data-testid="submit-btn"
            >
                {loggingIn ? 'Iniciando sesión...' : 'Comenzar'}
            </Button>
        </form>
    );
};

export default InternalStudentsTeachersLoginForm;
