import { EventDTO } from '@common/dtos/event-attendees/EventDTO';
import { Career } from '@app/domain/Career/Career';
export enum EventType {
    ONLINE = 1,
    ON_SITE = 2,
    HYBRID = 3,
}
export type EventProps = {
    id: string;
    name: string;
    startsAt: Date;
    type: EventType;
    dependency: {
        id: string;
    };
};
export class Event implements Career {
    constructor(public props: EventProps) {}
    /**
     * Career implementation
     */
    get key(): string {
        return this.props.id;
    }
    get name(): string {
        return this.props.name;
    }
}
export const eventFromDTO = (eventDTO: EventDTO): Event =>
    new Event({
        id: eventDTO.id,
        name: eventDTO.name,
        type: eventDTO.type as unknown as EventType,
        dependency: eventDTO.dependency,
        startsAt: new Date(eventDTO.starts_at),
    });
