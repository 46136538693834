import React, { useContext, useEffect, useState } from 'react';
import * as F from 'fp-ts/function';
import { swallowAsync } from '@app/helpers/swallowAsync';
import { ValidationError } from '@app/errors/ValidationError';
import { z } from 'zod';
import { useLocation } from 'react-router-dom';
import { ToastContext } from '@app/components/Toast/ToastContext';
import { eventAttendeesService } from '@app/services/event-attendees/EventAttendeesService';
import { EventAttendeeLoginLoadingScreen } from '@app/pages/event-attendee-login/EventAttendeeLoginLoadingScreen';
import { AuthenticationError } from '@app/errors/AuthenticationError';
import { LinearProgress } from '@material-ui/core';
import { ExclamationCircleIcon, UserIcon } from '@heroicons/react/solid';
import { InternalServerError } from '@app/errors/InternalServerError';
import { useUserPreferencesStore } from '@app/stores/UserPreferencesStore';
import { auth } from '@app/services/firebase';
import { PermissionDeniedError } from '@app/errors/PermissionDeniedError';
import { useTranslation } from 'react-i18next';
import { NotFoundError } from '@app/errors/NotFoundError';
import { useUserStore } from '@app/stores/UserStore';
export const EventAttendeeLoginTryLogin = () => {
    const { i18n } = useTranslation();
    const location = useLocation();
    const toast = useContext(ToastContext);
    const userPreferencesStore = useUserPreferencesStore();
    const userStore = useUserStore();
    const [errorMessage, setErrorMessage] = useState<null | string>(null);
    const login = async (email: string, invoice: string, dependencyId: string, eventId: string) => {
        const { jwt } = await eventAttendeesService.login(email, invoice, dependencyId);
        await auth.signInWithCustomToken(jwt);
        userPreferencesStore.setPreferredCareer(eventId);
    };
    useEffect(() => {
        (async () => {
            const eventAttendeeQueryParamsSchema = z.object({
                email: z.string(),
                invoice: z.string(),
                dependencyId: z.string(),
                eventId: z.string(),
            });
            const params = new URLSearchParams(location.search);
            const lang = params.get('lang');
            if (['en', 'es'].includes(lang!)) {
                await i18n.changeLanguage(lang!).catch();
            }
            const validationResult = eventAttendeeQueryParamsSchema.safeParse({
                email: params.get('email'),
                invoice: params.get('invoice'),
                dependencyId: params.get('dependencyId'),
                eventId: params.get('eventId'),
            });
            if (!validationResult.success) {
                return toast.open({
                    title: 'Parámetros inválidos',
                    message: JSON.stringify(validationResult.error.formErrors.fieldErrors),
                    closable: false,
                });
            }
            userStore.selectCareer({
                key: params.get('eventId')!,
                name: params.get('eventName')!,
            });
            const queryParams = validationResult.data;
            await F.flow(
                swallowAsync(ValidationError, () => setErrorMessage('Parámetros de inicio de sesión inválidos')),
                swallowAsync(AuthenticationError, () => setErrorMessage('Combinación folio y correo incorrecta')),
                swallowAsync(InternalServerError, () => setErrorMessage('Error en el servidor')),
                swallowAsync(PermissionDeniedError, () => setErrorMessage('No cuentas con eventos presenciales')),
                swallowAsync(NotFoundError, () => setErrorMessage('No existe el evento')),
                swallowAsync(AuthenticationError, () => setErrorMessage('Error desconocido'))
            )(login)(queryParams.email, queryParams.invoice, queryParams.dependencyId, queryParams.eventId);
        })();
    }, []);
    return (
        <EventAttendeeLoginLoadingScreen
            icon={
                errorMessage ? (
                    <ExclamationCircleIcon className="w-20 h-20 text-gray-200" />
                ) : (
                    <UserIcon className="w-20 h-20 text-gray-200" />
                )
            }
        >
            {errorMessage ? (
                <p className="text-white text-xl text-center">{errorMessage}</p>
            ) : (
                <>
                    <p className="text-white text-xl text-center">Iniciando sesión...</p>
                    <LinearProgress />
                </>
            )}
        </EventAttendeeLoginLoadingScreen>
    );
};
