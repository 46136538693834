import React, { useContext } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { AuthContext } from './FirebaseAuthProvider';
import { LoadingScreen } from '@app/components/LoadingScreen/LoadingScreen';

type PrivateRouteProps = {
    path: string;
    exact?: boolean;
    redirectTo: string;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ path, exact, redirectTo, children }) => {
    const authStoreState = useContext(AuthContext);
    if (authStoreState.isAuthenticatingUser) {
        return <LoadingScreen />;
    }
    return (
        <Route
            exact={exact}
            path={path}
            render={(props: RouteComponentProps) => (authStoreState.user ? children : <Redirect to={redirectTo} />)}
        />
    );
};
