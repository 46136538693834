import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { OnlyPublicRoute } from './components/FirebaseAuth/OnlyPublicRoute';
import { PrivateRoute } from './components/FirebaseAuth/PrivateRoute';
import InternalStudentsTeachersLogin from './pages/internal-students-teachers-login/InternalStudentsTeachersLogin';
import { ExternalStudentsLogin } from './pages/external-students-login/ExternalStudentsLogin';
import { Main } from '@app/pages/Main';
import TermsPage from '@app/pages/terms/Terms';
import { EventAttendeeLogin } from '@app/pages/event-attendee-login/EventAttendeeLogin';

const App = () => {
    const location = useLocation();
    return (
        <TransitionGroup>
            <CSSTransition key={location.pathname} classNames="fade" timeout={3000}>
                <Switch location={location}>
                    <OnlyPublicRoute path="/login" redirectTo="/">
                        <InternalStudentsTeachersLogin />
                    </OnlyPublicRoute>
                    <Route path="/event-attendee/login">
                        <EventAttendeeLogin />
                    </Route>
                    <OnlyPublicRoute path="/educacioncontinua" redirectTo="/">
                        <ExternalStudentsLogin />
                    </OnlyPublicRoute>
                    <PrivateRoute path="/app" redirectTo={'/login'}>
                        <Main />
                    </PrivateRoute>
                    <PrivateRoute exact path="/" redirectTo={'/login'}>
                        <TermsPage />
                    </PrivateRoute>
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    );
};

export default App;
