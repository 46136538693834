import { Profile, ProfileProps } from '@app/domain/Profile/Profile';
import { Role } from '@common/dtos/Role';
import { Careers } from '@app/domain/Career/Careers';
import { Events } from '@app/domain/EventAttendee/Events';
type EventAttendeeProfileProps = ProfileProps & {
    events: Events;
    role: Role.EVENT_ATTENDEE;
    canAnswerSurvey:
        | {
              success: true;
          }
        | {
              success: false;
              reason: string;
          };
};
export class EventAttendeeProfile extends Profile {
    constructor(public props: EventAttendeeProfileProps) {
        super(props);
    }
    public get careers(): Careers {
        return this.props.events;
    }
}
