import React from 'react'
import { Button, TextField, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useSecondStage } from './useSecondStage';
import firebase from "firebase/app"

export interface PhoneLoginSecondStageFormInputs {
	code: string
}

export interface PhoneLoginFirstStageFormProps {
	codeVerifier: firebase.auth.ConfirmationResult
}

export const PhoneLoginSecondStageForm = ({ codeVerifier }: PhoneLoginFirstStageFormProps) => {

	const { register, handleSubmit } = useForm<PhoneLoginSecondStageFormInputs>({
		reValidateMode: "onSubmit",
		defaultValues: {
			code: ""
		},
	});

	const { verifyCode, isVerifyingCode, isFormInvalid, validationErrors, verifyCodeError, codeVerified } = useSecondStage(codeVerifier)

	function formatInput(e: any) {
		// Prevent characters that are not numbers ("e", ".", "+" & "-")
		let checkIfNum;
		if (e.key !== undefined) {
			// Check if it's a "e", ".", "+" or "-"
			checkIfNum =
				e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
		} else if (e.keyCode !== undefined) {
			// Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
			checkIfNum =
				e.keyCode === 69 ||
				e.keyCode === 190 ||
				e.keyCode === 187 ||
				e.keyCode === 189;
		}
		return checkIfNum && e.preventDefault();
	};

	async function onSubmit(form: PhoneLoginSecondStageFormInputs) {
		await verifyCode(form)
	}

	return (
		<form className="flex flex-col space-y-4" onSubmit={handleSubmit(onSubmit)}>
			<Typography variant="body1" className="text-center text-gray-500">
				Enviamos un código por mensaje a tu celular, por favor introducelo para iniciar sesión
			</Typography>
			<Typography variant="body2" className="text-red-500">
				{verifyCodeError?.message} &nbsp;
			</Typography>
			<TextField
				name="Código"
				label="Código"
				error={!!validationErrors?.code}
				helperText={validationErrors?.code || "Introduce tu código"}
				variant="outlined"
				size="small"
				inputProps={{
					...register("code"),
					type: "number",
					id: "Código",
				}}
			/>
			<Button
				type="submit"
				disabled={isVerifyingCode}
				aria-label="submit"
				color="primary"
				variant="contained"
				fullWidth
			>
				Verificar código
			</Button>
		</form>
	)
}
