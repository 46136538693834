import { Careers } from '@app/domain/Career/Careers';
import { Event } from './Event';
import { Career } from '@app/domain/Career/Career';
export type EventsProps = {
    value: Event[];
};
export class Events extends Careers {
    constructor(public props: EventsProps) {
        super();
    }
    get value(): Career[] {
        return this.props.value;
    }
}
