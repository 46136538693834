import {
    Button,
    Fade,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useUserStore } from '@app/stores/UserStore';
import { pretifyCarrera } from '../../components/helpers/pretifyCarrera';
import { useProfile } from '@app/services/api/useProfile';
import { useSendSurveyMutation } from '@app/services/api/survey';
import { pdfjs } from 'react-pdf';
import { AuthContext } from '@app/components/FirebaseAuth/FirebaseAuthProvider';
import { logout } from '@app/services/api/auth/logout/logout';
import { LoadingScreen } from '@app/components/LoadingScreen/LoadingScreen';
import { ExternalStudentProfile } from '@app/domain/Profile/ExternalStudentProfile';
import { TeacherProfile } from '@app/domain/Profile/TeacherProfile';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
export type PROFILE_PAGE_SECTION = 'PROFILE' | 'NOTIFY_SIMPTOMS' | 'VACCINATION_CERTIFICATE';
const Profile = () => {
    const [section, setSection] = useState<PROFILE_PAGE_SECTION>('PROFILE');
    const { profile, loadingProfile } = useProfile();
    const { selectedCareer, selectCareer } = useUserStore();
    const { user } = useContext(AuthContext);
    const { sendSurvey, sendingSurvey, surveySent, surveySentError } = useSendSurveyMutation({
        onSuccess: () => {
            setSection('PROFILE');
        },
    });
    if (selectedCareer === null || loadingProfile) return <LoadingScreen />;
    const onSurveySubmit = async (resps: boolean[]) => {
        await sendSurvey({
            answers: resps,
            clave_carrera: selectedCareer.key,
        });
        return {
            sent: true,
        };
    };
    const handleLogout = () => logout(() => selectCareer(null));
    const selectorName = profile instanceof TeacherProfile ? 'Dependencia' : 'Carrera';
    return (
        <div>
            <Fade in={section === 'PROFILE'} timeout={400}>
                <Paper
                    className="p-4 w-11/12 md:w-6/12 lg:w-4/12 mx-auto fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    elevation={7}
                >
                    <ProfileContainer>
                        <Typography
                            sx={{ mb: '0.5em' }}
                            align="center"
                            fontWeight="600"
                            variant="h5"
                            className="mb-4 text-gray-600"
                        >
                            {pretifyCarrera(profile!.props.name)} -{' '}
                            {profile instanceof ExternalStudentProfile
                                ? profile.careers.value.find((career) => career.props.name === selectedCareer.name)!
                                      .props.enrollment
                                : profile!.props.userId}
                        </Typography>
                        <ProfileImgContainer className="mb-4">
                            <img src={`data:image/png;base64,${profile!.props.photo}`} alt="Imagen de perfil" />
                        </ProfileImgContainer>
                        <FormControl key={selectorName} sx={{ m: 1, width: '90%' }}>
                            <InputLabel
                                sx={{ backgroundColor: 'white', paddingRight: '6px' }}
                                id="carrera-selctor-label"
                            >
                                {selectorName}
                            </InputLabel>
                            <Select
                                labelId="carrera-selector-label"
                                id="carrera-selector"
                                value={selectedCareer.name}
                                label="Carrera"
                                size="small"
                                onChange={(e) => {
                                    selectCareer(
                                        profile!.careers.value.find((career) => career.name === e.target.value)!
                                    );
                                }}
                            >
                                {profile!.careers.value.map((career) => {
                                    return (
                                        <MenuItem sx={{ maxWidth: '80vw' }} key={career.name} value={career.name}>
                                            {pretifyCarrera(career.name)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            <FormHelperText>{selectorName} seleccionada</FormHelperText>
                        </FormControl>
                        <Button
                            sx={{ mt: '1em' }}
                            onClick={handleLogout}
                            variant="contained"
                            color="primary"
                            className="mt-4"
                            data-testid="logout-btn"
                        >
                            Cerrar sesión
                        </Button>
                    </ProfileContainer>
                </Paper>
            </Fade>
        </div>
    );
};

const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ProfileImgContainer = styled.div`
    height: 200px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
        height: 100%;
        border-radius: 5px;
    }

    @media (max-height: 600px), (max-width: 370px) {
        height: 100px;
        width: 100px;
    }
`;

export default Profile;
