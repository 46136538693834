import create from 'zustand';
import produce from 'immer';
import { Career } from 'src/domain/Career/Career';
type UserStoreState = {
    fcmToken: string | null;
    setFcmToken(fcmToken: string | null): void;
    selectedCareer: Career | null;
    selectCareer(career: Career | null): void;
};
export const useUserStore = create<UserStoreState>((set, get) => ({
    fcmToken: null,
    setFcmToken: (fcmToken: string) =>
        set(
            produce<UserStoreState>((state) => {
                state.fcmToken = fcmToken;
            })
        ),
    selectedCareer: null,
    selectCareer: (career: Career | null) =>
        set(
            produce<UserStoreState>((state) => {
                state.selectedCareer = career;
            })
        ),
}));
