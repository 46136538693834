import create from 'zustand';
import { persist } from 'zustand/middleware';
import produce from 'immer';
type UserPreferencesStoreState = {
    preferredCareerKey: string | null;
};
type UserPreferencesStoreMethods = {
    setPreferredCareer(pc: string | null): void;
};
export const useUserPreferencesStore = create<UserPreferencesStoreState & UserPreferencesStoreMethods>(
    persist(
        (set, _) => ({
            preferredCareerKey: null,
            setPreferredCareer: (preferredCareer: string | null) =>
                set(
                    produce<UserPreferencesStoreState>((state) => {
                        state.preferredCareerKey = preferredCareer;
                    })
                ),
        }),
        { name: 'userPreferences' }
    )
);
