import { ExternalStudentCareerDTO } from './ExternalStudentCareerDTO';
import { InternalStudentCareerDTO } from './InternalStudentCareerDTO';
import { TeacherDependencyDTO } from '@common/dtos/TeacherDependencyDTO';
import { Role } from '@common/dtos/Role';
import { EventDTO } from '@common/dtos/event-attendees/EventDTO';
export type CommonProfileDTO = {
    user_id: string;
    tiene_sintomas: boolean;
    encuesta_contestada: boolean;
    fecha_contestada: number; // In ms
    dias_bloqueado: number;
    foto: string;
    name: string;
    unseen_notifications: number;
    vaccinationCertificateUrl: string | null;
    vaccinationCertificateStatus: string | null;
    latestSurveyIsFromToday: boolean;
};
export type ExternalStudentProfileDTO = CommonProfileDTO & {
    role: Role.STUDENT;
    haveAllCareersExpired: boolean;
    puedo_contestar_encuesta: boolean;
    careers: ExternalStudentCareerDTO[];
};
export type InternalStudentProfileDTO = CommonProfileDTO & {
    selectedDependencyId: string;
    role: Role.STUDENT;
    enrollment: string;
    careers: InternalStudentCareerDTO[];
};
export type TeacherProfileDTO = CommonProfileDTO & {
    dependencies: TeacherDependencyDTO[];
    teacherKey: string;
    role: Role.TEACHER;
};
export type EventAttendeeProfileDTO = CommonProfileDTO & {
    events: EventDTO[];
    can_answer_survey: {
        success: boolean;
        reason: string | null;
    };
    role: Role.EVENT_ATTENDEE;
};
export type ProfileDTO =
    | ExternalStudentProfileDTO
    | InternalStudentProfileDTO
    | TeacherProfileDTO
    | EventAttendeeProfileDTO;
export function isInternalStudentProfileDTO(profileDTO: ProfileDTO): profileDTO is InternalStudentProfileDTO {
    return !('haveAllCareersExpired' in profileDTO) && !('teacherKey' in profileDTO) && !('events' in profileDTO);
}
export const isTeacherProfileDTO = (profileDTO: ProfileDTO): profileDTO is TeacherProfileDTO => {
    return 'teacherKey' in profileDTO;
};
export const isEventAttendeeProfileDTO = (profileDTO: ProfileDTO): profileDTO is EventAttendeeProfileDTO => {
    return !!(profileDTO as EventAttendeeProfileDTO)?.events;
};
