import React, { ReactNode } from 'react';
type EventAttendeeLoginLoadingScreenProps = {
    children: ReactNode;
    icon: ReactNode;
};
export const EventAttendeeLoginLoadingScreen = ({ children, icon }: EventAttendeeLoginLoadingScreenProps) => {
    return (
        <div className="bg-primary min-w-screen min-h-screen flex flex-col space-y-4 justify-center items-center">
            <div className="w-32 h-32 bg-white bg-opacity-10 grid place-items-center rounded-full">{icon}</div>
            <div className="w-3/4 md:w-1/2 lg:w-1/3 flex flex-col space-y-4">{children}</div>
        </div>
    );
};
