import { Paper, Typography, useMediaQuery } from '@material-ui/core';
import { formatDistance } from 'date-fns';
import es from 'date-fns/locale/es';
import React, { useContext, useRef } from 'react';
import { NotificationDTO } from '@common/dtos/NotificationDTO';
import { ToastContext } from '@app/components/Toast/ToastContext';
type NotificationProps = {
    notification: NotificationDTO;
};
export const Notification = ({ notification }: NotificationProps) => {
    const isSmallMobile = useMediaQuery('(max-width: 320px)');
    const isMobile = useMediaQuery('(max-width: 600px)');
    const toast = useContext(ToastContext);
    const messageRef = useRef<HTMLDivElement>(null);
    const showNotification = (notif: NotificationDTO) => {
        toast.open({
            title: notif.title,
            message: notif.message,
        });
    };
    let maxMessageLength = 80;
    if (isMobile) {
        maxMessageLength = 30;
    }
    if (isSmallMobile) {
        maxMessageLength = 20;
    }
    let maxTitleLength = 80;
    if (isMobile) {
        maxTitleLength = 20;
    }
    if (isSmallMobile) {
        maxTitleLength = 20;
    }
    return (
        <Paper
            key={notification.created_at}
            elevation={7}
            sx={{
                width: '100%',
                p: isMobile ? '1em' : '1em',
                my: '2em',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
            }}
        >
            <div className="flex flex-row h-20">
                <div className="h-20 w-20 flex-shrink-0 bg-gray-500 rounded-md text-white justify-center flex items-center text-2xl">
                    <i className="fas fa-bell"></i>
                </div>
                <div
                    className="flex flex-col pl-4"
                    style={{
                        wordBreak: 'break-all',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                    }}
                >
                    <p className="text-base">{notification.title.slice(0, maxTitleLength)}</p>
                    <Typography ref={messageRef} color="#222222" variant="body2">
                        {notification.message.slice(0, maxMessageLength)}
                        {notification.message.length > maxMessageLength && (
                            <>
                                {'... '}
                                <button onClick={() => showNotification(notification)} className="text-primary inline">
                                    ver más
                                </button>
                            </>
                        )}
                    </Typography>
                </div>
            </div>
            {!notification.seen && (
                <div className="bg-yellow-400 text-gray-600 absolute px-2 rounded-md -top-4  -right-3">Nueva</div>
            )}
            <div className="flex justify-end mt-2 absolute bottom-2 right-3">
                <Typography className="text-gray-600" variant="caption">
                    Hace {formatDistance(new Date(notification.created_at), new Date(), { locale: es })}
                </Typography>
            </div>
        </Paper>
    );
};
