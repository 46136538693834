import React, { useContext, useEffect, useState } from 'react';
import qr from 'qrcode';
import { Fade, Typography } from '@material-ui/core';
import { useProfile } from '@app/services/api/useProfile';
import { useUserStore } from '@app/stores/UserStore';
import { AuthContext } from '@app/components/FirebaseAuth/FirebaseAuthProvider';
import { ExternalStudentProfile } from '@app/domain/Profile/ExternalStudentProfile';
import { useTranslation } from 'react-i18next';

const QRCode = () => {
    const { t } = useTranslation();
    const { selectedCareer } = useUserStore();
    const { profile, loadingProfile } = useProfile();
    const { user } = useContext(AuthContext);
    const [qrImg, setQrImg] = useState<string | null>(null);
    const color = '#059669';
    const message = t('qrcode.welcome');
    useEffect(() => {
        const generateQRCode = async () => {
            const img = await qr.toDataURL(
                JSON.stringify({
                    matricula:
                        profile instanceof ExternalStudentProfile
                            ? profile.careers.value.find((career) => career.props.name === selectedCareer!.name)!.props
                                  .enrollment
                            : profile!.props.userId,
                    clave_carrera: selectedCareer!.key,
                    carreras: profile!.careers.keys,
                }),
                {
                    color: { dark: color },
                }
            );
            setQrImg(img);
        };
        generateQRCode();
    }, [loadingProfile]);
    return (
        <div>
            {qrImg && (
                <div className="absolute  w-11/12 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <Typography variant="h6" className="mt-4 text-gray-600 " sx={{ mb: '0.5em' }} align="center">
                        {selectedCareer!.name}
                    </Typography>
                    <img className="w-[min(60vw,300px)] rounded-sm mx-auto mb-4" src={qrImg} alt="Código qr" />
                    <Fade in={message !== ''}>
                        <Typography
                            className="text-gray-500 text-center w-11/12 md:w-1/2 lg:w-3/4"
                            sx={{ mx: 'auto' }}
                            variant="h6"
                        >
                            {message} &nbsp;
                        </Typography>
                    </Fade>
                </div>
            )}
        </div>
    );
};

export default React.memo(QRCode);
