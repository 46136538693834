import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { useNotifications } from '../../services/api/notifications.';
// @ts-ignore
import ReadMore from 'read-more-react';
import { BellIcon } from '@heroicons/react/solid';
import { Notification } from '@app/pages/notifications/Notification';
const Notifications = () => {
    const { notifications, couldNotGetNotifications, getNotificationsError, gettingNotifications } = useNotifications();
    const areNotificationsEmpty = notifications?.length === 0;

    return (
        <div className="w-full flex justify-center">
            <div className="w-11/12 md:w-2/3 lg:w-1/2 xl:w-1/3">
                {!gettingNotifications && couldNotGetNotifications && (
                    <Typography color="red">{getNotificationsError?.message}</Typography>
                )}
                {gettingNotifications && !couldNotGetNotifications && (
                    <div className="flex w-full flex-col justify-center items-center">
                        <CircularProgress />
                    </div>
                )}
                {!gettingNotifications && !couldNotGetNotifications && areNotificationsEmpty && (
                    <div>
                        <div className="w-24 h-24 bg-gray-500 opacity-10 mx-auto rounded-full mb-4 grid place-items-center">
                            <BellIcon className="h-16 w-16" />
                        </div>
                        <Typography className="text-gray-500" align="center">
                            Sin notificaciones
                        </Typography>
                    </div>
                )}
                {!gettingNotifications &&
                    !getNotificationsError &&
                    !areNotificationsEmpty &&
                    notifications?.map((notification) => (
                        <Notification key={notification.created_at} notification={notification} />
                    ))}
            </div>
        </div>
    );
};

export default Notifications;
