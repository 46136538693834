import { API_BASE_URL } from '@app/services/constants';
import { ProfileDTO } from '@common/dtos/ProfileDTO';
import axios from 'axios';
import { useQuery } from 'react-query';
import { Profile } from '@app/domain/Profile/Profile';
import { useUserStore } from '@app/stores/UserStore';
import { useUserPreferencesStore } from '@app/stores/UserPreferencesStore';
import { userProfileFromDTO } from '@app/domain/Profile/userProfileFromDTO';
import { ValidationError } from '@app/errors/ValidationError';
import { NotFoundError } from '@app/errors/NotFoundError';
export const useProfile = () => {
    const { selectedCareer, fcmToken, selectCareer } = useUserStore();
    const userPreferences = useUserPreferencesStore();
    const {
        data: profile,
        error,
        isLoading,
        isError,
    } = useQuery<Profile>(
        ['profile', selectedCareer],
        async (): Promise<Profile> => {
            const careerKeyForProfile = selectedCareer
                ? selectedCareer.key
                : userPreferences.preferredCareerKey ?? null;
            const { data } = await axios.post<ProfileDTO>(`${API_BASE_URL}/auth/userinfo`, {
                clave_carrera: careerKeyForProfile,
                token_fcm: fcmToken,
            });
            const profile = userProfileFromDTO(data);
            // @todo Verificar que hacer cuando no tiene carreras
            const preferredCareer = profile.careers.value.find((career) => career.key === careerKeyForProfile);
            if (selectedCareer === null) selectCareer(preferredCareer ?? profile.careers.value[0]);
            return profile;
        },
        {
            retry: false,
            refetchOnWindowFocus: false,
            onError: (e) => {
                console.log(e instanceof ValidationError);
                console.log(e instanceof NotFoundError);
                console.log(e);
            },
        }
    );
    return {
        profile,
        error,
        loadingProfile: isLoading,
        errorLoadingProfile: isError,
    };
};
