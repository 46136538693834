import { Careers } from '@app/domain/Career/Careers';
export type ProfileProps = {
    userId: string;
    name: string;
    hasSimptoms: boolean;
    lastSurveyWasAnsweredAt: Date;
    daysBlocked: number;
    canAnswerAgain: boolean;
    photo: string;
    selectedDependencyId: string;
    latestSurveyIsFromToday: boolean;

    unseenNotifications: number;
    vaccinationCertificate: {
        url: string | null;
        status: string | null;
    };
};
export abstract class Profile {
    constructor(public props: ProfileProps) {}
    public abstract get careers(): Careers;
    public setUnseenNotifications(unseenNotifications: number): Profile {
        this.props.unseenNotifications = unseenNotifications;
        return this;
    }
}
