import { TeacherDependencyDTO } from '@common/dtos/TeacherDependencyDTO';
import { Career } from '@app/domain/Career/Career';
export type TeacherDependencyProps = {
    key: string;
    name: string;
};
export class TeacherDependency implements Career {
    constructor(public props: TeacherDependencyProps) {}
    /**
     * Career implementation
     */
    get key(): string {
        return this.props.key;
    }
    get name(): string {
        return this.props.name;
    }
}
export const teacherDependencyFromDTO = (dto: TeacherDependencyDTO): TeacherDependency => {
    return new TeacherDependency({
        name: dto.name,
        key: dto.key,
    });
};
