import axios from 'axios';
import { useQuery } from 'react-query';
import { API_BASE_URL } from '../constants';
import { NotificationDTO } from '@common/dtos/NotificationDTO';
import queryClient from '@app/clients/queryClient';
import { useUserStore } from '@app/stores/UserStore';
import { Profile } from '@app/domain/Profile/Profile';

export const useNotifications = () => {
    const { selectedCareer } = useUserStore();
    const { data, isError, error, isLoading } = useQuery<void, Error, NotificationDTO[]>('notifications', async () => {
        try {
            const { data } = await axios.get(`${API_BASE_URL}/notification`);
            const currentProfile = queryClient.getQueryData<Profile>(['profile', selectedCareer]);
            queryClient.setQueryData(['profile', selectedCareer], currentProfile?.setUnseenNotifications(0));
            return data.notifications;
        } catch (error) {
            throw new Error('Error al intentar obtener notificaciones');
        }
    });
    return {
        notifications: data,
        couldNotGetNotifications: isError,
        getNotificationsError: error,
        gettingNotifications: isLoading,
    };
};
