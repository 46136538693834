import { apiHttpClient } from '@app/clients/http/apiHttpClient';
import axios, { AxiosError } from 'axios';
import { ValidationError } from '@app/errors/ValidationError';
import { InternalServerError } from '@app/errors/InternalServerError';
import { AuthenticationError } from '@app/errors/AuthenticationError';
import { PermissionDeniedError } from '@app/errors/PermissionDeniedError';
import { UnknownError } from '@app/errors/UnknownError';
import { NotFoundError } from '@app/errors/NotFoundError';
const errorInterceptor = (error: AxiosError) => {
    switch (error?.response?.status) {
        case 400:
            return Promise.reject(new ValidationError(error.response?.data?.errors ?? {}));
        case 401:
            return Promise.reject(new AuthenticationError());
        case 404:
            return Promise.reject(new NotFoundError());
        case 403:
            return Promise.reject(new PermissionDeniedError(error.response?.data?.message ?? 'Error desconocido'));
        case 500:
            return Promise.reject(new InternalServerError());
        default:
            return Promise.reject(new UnknownError());
    }
};

apiHttpClient.interceptors.response.use((response) => response, errorInterceptor);
axios.interceptors.response.use((response) => response, errorInterceptor);
