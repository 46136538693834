import { Careers } from '@app/domain/Career/Careers';
import { ExternalStudentCareer } from '@app/domain/ExternalStudent/ExternalStudentCareer';
export type ExternalStudentCareersProps = {
    value: ExternalStudentCareer[];
};
export class ExternalStudentCareers extends Careers {
    constructor(public props: ExternalStudentCareersProps) {
        super();
    }
    public get value(): ExternalStudentCareer[] {
        return this.props.value;
    }
}
