import { Button, TextField, Typography } from '@material-ui/core';
import React from 'react'
import { useForm } from 'react-hook-form';
import { useFirstStage } from './useFirstStage';
import firebase from "firebase/app"

export interface PhoneLoginFirstStageFormInputs {
	matricula: string;
	phoneNumber: string;
}

export interface PhoneLoginFirstStageFormProps {
	onMessageSent: React.Dispatch<React.SetStateAction<firebase.auth.ConfirmationResult | null>>
}

export const PhoneLoginFirstStageForm = ({ onMessageSent }: PhoneLoginFirstStageFormProps) => {

	const { register, handleSubmit } = useForm<PhoneLoginFirstStageFormInputs>({
		reValidateMode: "onSubmit",
		defaultValues: {
			matricula: "",
			phoneNumber: "",
		},
	});

	const { sendMessage, isSendingMessage, isFormInvalid, validationErrors, sendMessageError, messageSent } = useFirstStage(onMessageSent)

	function formatInput(e: any) {
		// Prevent characters that are not numbers ("e", ".", "+" & "-")
		let checkIfNum;
		if (e.key !== undefined) {
			// Check if it's a "e", ".", "+" or "-"
			checkIfNum =
				e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
		} else if (e.keyCode !== undefined) {
			// Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
			checkIfNum =
				e.keyCode === 69 ||
				e.keyCode === 190 ||
				e.keyCode === 187 ||
				e.keyCode === 189;
		}
		return checkIfNum && e.preventDefault();
	};
	async function onSubmit(form: PhoneLoginFirstStageFormInputs) {
		await sendMessage(form)
	}
	return (
		<form className="flex flex-col space-y-4" onSubmit={handleSubmit(onSubmit)}>
			<div id="recaptcha_verifier"></div>
			<Typography variant="body2" className="text-red-500">
				{sendMessageError?.message} &nbsp;
			</Typography>
			<TextField
				name="Matrícula"
				label="Matrícula"
				error={!!validationErrors?.matricula}
				helperText={validationErrors?.matricula || "Introduce tu matrícula"}
				variant="outlined"
				size="small"
				type="number"
				onKeyDown={formatInput}
				inputProps={{
					...register("matricula", {}),
					"aria-label": "matricula",
					min: "0",
					id: "Matrícula",
				}}
			/>
			<TextField
				name="Celular"
				label="Celular"
				error={!!validationErrors?.phoneNumber}
				helperText={validationErrors?.phoneNumber || "Introduce tu número de celular"}
				variant="outlined"
				size="small"
				inputProps={{
					...register("phoneNumber"),
					type: "number",
					id: "Teléfono",
				}}
			/>
			<Button
				type="submit"
				disabled={isSendingMessage}
				aria-label="submit"
				color="primary"
				variant="contained"
				fullWidth
			>
				Enviar mensaje
			</Button>
		</form>
	)
}
