import { navigateToFaq } from '@app/components/helpers/navigateToFaq';
import { IconButton, Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import React from 'react';
const InternalStudentsTeachersLoginLayout: React.FC = ({ children }) => {
    return (
        <div className="relative flex flex-col justify-center w-full h-full bg-white Login items-center">
            <IconButton onClick={navigateToFaq} sx={{ position: 'absolute' }} className="top-4 right-4">
                <Help className="text-primary" />
            </IconButton>
            <img src="/images/LogoGrande.png" alt="Logo" className="mb-2 md:mb-4 h-20 md:h-28" />
            <div className="w-11/12 md:w-1/2 lg:w-4/12 p-4 shadow-lg py-12 flex flex-col space-y-2">
                <Typography align="center" variant="h5">
                    Bienvenido de vuelta
                </Typography>
                {children}
            </div>
            <img src="/images/LogoUANLGrande.png" alt="Logo" className="mt-4 h-16 bottom-12" />
        </div>
    );
};

export default InternalStudentsTeachersLoginLayout;
