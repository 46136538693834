import { Profile, ProfileProps } from '@app/domain/Profile/Profile';
import { Careers } from '@app/domain/Career/Careers';
import { InternalStudentCareers } from '@app/domain/InternalStudent/InternalStudentCareers';
export type InternalStudentProfileProps = ProfileProps & {
    careers: InternalStudentCareers;
    enrollment: string;
};
export class InternalStudentProfile extends Profile {
    constructor(public props: InternalStudentProfileProps) {
        super(props);
    }
    public get careers(): Careers {
        return this.props.careers;
    }
}
