import { Career } from '@app/domain/Career/Career';
export abstract class Careers {
    abstract get value(): Career[];
    public get keys(): string[] {
        return this.value.map((career) => career.key);
    }
    public isEmpty() {
        return this.value.length > 1;
    }
}
